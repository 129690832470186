<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-form ref="frmFilter">
              <v-row class="mt-8">
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>Company
                    Warehouse</label
                  >
                  <v-autocomplete
                    v-model="compnay_warehouse"
                    :items="compnay_warehouses"
                    item-text="name"
                    item-value="id"
                    return-object
                    color="rgba(0,0,0,.6)"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    placeholder="Select a Vendor"
                    :rules="[(v) => !!v || 'Vendor is Required']"
                    outlined
                    single-line
                    height="48"
                    @change="getPo"
                  >
                  </v-autocomplete> </v-col
                ><v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>Po
                    Number</label
                  >
                  <v-autocomplete
                    v-model="poNumber"
                    :items="poNumbers"
                    item-text="po_number"
                    item-value="id"
                    return-object
                    color="rgba(0,0,0,.6)"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mt-5
                      mb-0
                    "
                    placeholder="Select a PO Number"
                    :rules="[(v) => !!v || 'PO number is Required']"
                    outlined
                    single-line
                    height="48"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end mx-5">
                  <v-btn
                    @click="clearfilter"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-secondary
                      bg-light
                      py-3
                      px-6
                      mr-2
                    "
                    >Clear</v-btn
                  >

                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                    "
                    @click="search"
                    >Search</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- ## Dialog Dispatch Master -->

      <v-dialog v-model="master_dialog" max-width="700px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Dispatch List</span>
          </v-card-title>
          <v-card-text class="px-0 py-0">
            <v-container>
              <v-data-table
                :items="DispatchMaster"
                :headers="masterHeader"
                mobile-breakpoint="0"
                :footer-props="{
                  'items-per-page-options': [100, 200, 500, -1],
                }"
                fixed-header
                class="table"
                ><template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    @click="viewDetails(item)"
                    text
                    elevation="0"
                    :ripple="false"
                    height="43"
                    color="red"
                    >View Details</v-btn
                  >
                  <!-- <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!--  ## Data Table Disptach Child List  -->
      <v-form ref="frmTable" v-show="dispatchChildShow">
        <v-card class="card-shadow border-radius-xl mt-6">
          <v-card-text class="px-0 py-0">
            <v-data-table
              v-model="selectedData"
              :headers="headers"
              :items="purchaseDetails"
              item-key="id"
              mobile-breakpoint="0"
              :footer-props="{
                'items-per-page-options': [100, 200, 500, -1],
              }"
              fixed-header
              class="table"
            >
              <template v-slot:top>
                <v-toolbar flat
                  ><v-row
                    ><v-col cols="3">
                      <v-menu
                        ref="mnu_date"
                        v-model="mnu_date"
                        :close-on-content-click="false"
                        :return-value.sync="mnu_date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-5
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            placeholder="Date"
                            persistent-hint
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="green lighten-1"
                          header-color="primary"
                          v-model="date"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="mnu_date = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.mnu_date.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col></v-row
                  >
                  <v-spacer> </v-spacer>
                  <v-btn
                    @click="save"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                    "
                    >Save
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.received_no_of_packages`]="{ item }">
                <div>
                  <v-text-field
                    v-model.number="item.received_no_of_packages"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    :rules="[
                      (v) => v >= 0 || 'Min should be above 0',
                      (v) =>
                        v <= item.ordered_no_of_packages ||
                        `Max should not be above ${item.ordered_no_of_packages}`,
                    ]"
                    type="number"
                    min="0"
                  ></v-text-field>
                </div> </template
              ><template v-slot:[`item.received_quantity`]="{ item }">
                <div>
                  <v-text-field
                    v-model="item.received_quantity"
                    v-on="
                      (item.received_quantity =
                        item.received_no_of_packages * item.unit_weight)
                    "
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    type="number"
                    min="0"
                  ></v-text-field>
                </div>
              </template>

              <template v-slot:[`item.bay`]="{ item }">
                <div>
                  <v-text-field
                    v-model="item.bay"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    :rules="[
                      (v) =>
                        item.received_quantity == 0 ||
                        !!v ||
                        'Please Enter Bay',
                    ]"
                  ></v-text-field>
                </div>
              </template>
            </v-data-table> </v-card-text
          ><v-card-actions>
            <v-row>
              <v-col offset-md="5">
                <v-btn
                  @click="openSave"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                  "
                  >Save
                </v-btn></v-col
              >
            </v-row>
          </v-card-actions>
        </v-card> </v-form
      ><v-dialog v-model="dialogSave" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center"
            >Do you want to Save</v-card-title
          >
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDailogSave"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="save"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
              "
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogClosePO" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center"
            >Do you want to CLOSE this PO</v-card-title
          >
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDailogClosePo"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="closePo"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
              "
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
export default {
  data() {
    return {
      purchaseDetails: [],
      selectedData: [],
      dialogSave: false,

      vendorType: "OWN",
      type: "INTERNAL TRANSFER",
      compnay_warehouse: null,
      compnay_warehouses: [],
      poNumber: null,
      poNumbers: [],
      overlay: false,
      dispatchChildShow: false,
      master_dialog: false,
      dialogClosePO: false,
      DispatchMaster: [],
      bay: "",
      masterHeader: [
        {
          text: "Mode Of Transport",
          value: "mode_of_transport",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Vehicle Number",
          value: "vehicle_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Driver Contact Number",
          value: "driver_contact_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "",
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
      ],

      headers: [
        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 200,
        },
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Invoice No",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Mark",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Unit Weight",
          value: "unit_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Ordered (Packages)",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Recieved (Packages)",
          value: "received_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Order Quantity (KGs)",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Recieved Quantity (KGs)",
          value: "received_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Bay",
          value: "bay",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
      ], //
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
    };
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.overlay = true;
      this.compnay_warehouses = await api.getCompanyWareHouse();
      this.overlay = false;
    },

    async getPo() {
      try {
        this.poNumbers = await api.getPo(this.compnay_warehouse.id);
      } catch (error) {}
    },
    async search() {
      if (this.validateFrmFilter()) {
        try {
          this.overlay = true;
          this.DispatchMaster = await api.getDispatchMaster(
            this.poNumber.po_number,
            this.type
          );
          this.overlay = false;
          this.master_dialog = true;
          this.dispatchChildShow = false;
          this.purchaseDetails = [];
          this.selectedData = [];

          console.log("this.result", this.DispatchMaster);
        } catch (error) {
          console.log(error);
        }
      } else {
      }
    },

    async viewDetails(item) {
      let result = await api.getPurchasedetailsForUpdateDispatch(item.id);

      this.purchaseDetails = result;
      this.selectedData = this.purchaseDetails;
      this.master_dialog = false;
      this.dispatchChildShow = true;
    },
    validateFrmFilter() {
      return this.$refs.frmFilter.validate();
    },
    validatefrmType() {
      return this.$refs.frmType.validate();
    },

    validTable() {
      return this.$refs.frmTable.validate();
    },
    clearfilter() {
      this.compnay_warehouse = null;
      this.poNumber = null;
      this.poNumbers = [];
      this.$refs.frmFilter.resetValidation();
    },
    openSave() {
      this.dialogSave = true;
    },
    closeDailogSave() {
      this.dialogSave = false;
    },
    // Update Dipatch And Inventory After item Recieved.
    async save() {
      this.dialogSave = false;
      if (this.selectedData.length > 0) {
        if (this.validTable()) {
          this.overlay = true;
          let req = {
            child: this.selectedData,
            po_number: this.poNumber.po_number,
            purchase_type: this.type,
            bay: this.bay,
            delivery_date: this.date,
          };
          console.log("req", req);
          try {
            await api.update(req);
            this.$refs.frmFilter.resetValidation();

            this.overlay = false;
            this.dialogClosePO = true;
          } catch (error) {
            // console.log(error);
            this.showErrorAlert(error);
          }
        }
      } else {
        this.showWarningAlert("Select Atleast one Item");
      }
    },

    closeDailogClosePo() {
      this.dialogClosePO = false;
      this.clear();
      this.showSuccessAlert("Dispatch updated");
    },

    clear() {
      this.selectedData = [];
      this.purchaseDetails = [];
    },
    async closePo() {
      try {
        console.log(this.poNumber);
        await api.closePo(this.poNumber.po_number);
        this.showSuccessAlert("Dispatch updated and PO closed");
        this.dialogClosePO = false;
        this.clear();
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",

        icon: "success",
        showConfirmButton: true,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    },
    nodDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
  },
};
</script>

<style></style>
