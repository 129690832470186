import axios from "axios";

export default {
  async getVendorsByType(type) {
    return await axios
      .get("vendors/vendor/by/type/" + type)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getCompanyWareHouse() {
    return await axios
      .get("/company-warehouse")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getVendors() {
    return await axios
      .get("vendors")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getPo(compny_id) {
    return await axios
      .get(`purchase-internal-transfer/get-po-by-company-warehouse/` + compny_id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getDispatchMaster(po_number, purchase_type) {
    return await axios
      .get(`/dispatch/master-by-po-number/${encodeURIComponent(po_number)}?purchase_type=${purchase_type}`)
      .then((res) => {
        console.log(res.data);

        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getPurchaseDeatils(po_number, purchase_type) {
    return await axios
      .get(`/purchase-auction/purchase/details/${po_number}?purchase_type=${purchase_type}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getPurchasedetailsForUpdateDispatch(master_id) {
    return await axios
      .get(`/dispatch/dispatch/details/for-update/${master_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async createDispatch(req) {
    return await axios
      .post("/dispatch", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async update(req) {
    return await axios
      .patch("/dispatch", req)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },
  async closePo(po_number) {
    return await axios
      .patch("/purchase-auction/close-PO/" + po_number)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },
};
